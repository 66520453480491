import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—August 2008" />
	<Old2005BlogPage>
		<h2>August 2008</h2>



		<h3 className="blogdate">
		Sunday, August 17, 2008
		</h3>






		<h4 className="blogitemtitle">
		Winter lightning
		</h4>





			<div className="blogitembody">
			Winter in Wellington.<br />Thunder and lightning, hail and rain<br />Crashing down tumultuously.<br />Never have I seen such a mid-winter spectacle.<br />This kind of heavenly behaviour I've only seen mid-summer.<br />I am, after all, from B.C. and Alberta.<br /><br />I was caught in it, surprised, during my late evening<br />Walk out to the corner store to get some melodies.<br />I danced soaked with glee.<br />I walked through gushing icy streams that raced down streets towards the bay;<br />The sidewalks covered in crunch-crunchy pellets.<br />I giggled absurdly and took shelter next to a parked car<br />As the wind lashed me with it's angry kernels.<br /><br />When I returned<br />She rolled me up on the floor.<br />Hot cocoa, nuzzle-snuggles,<br />And I was warm with wonder.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:32 PM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
